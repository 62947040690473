import * as Yup from "yup"

export const upload = Yup.object().shape({
  userName: Yup.string().required("ESTE CAMPO ES OBLIGATORIO"),
  email: Yup.string()
    .email("EMAIL INVÁLIDO")
    .required("ESTE CAMPO ES OBLIGATORIO"),
  province: Yup.string().required("ESTE CAMPO ES OBLIGATORIO"),
  // location: Yup.string()
  // 	.required('ESTE CAMPO ES OBLIGATORIO'),
  instagram: Yup.string().required("ESTE CAMPO ES OBLIGATORIO"),
  // artist: Yup.string()
  // 	.required('ESTE CAMPO ES OBLIGATORIO'),
  song: Yup.string().required("ESTE CAMPO ES OBLIGATORIO"),
  birthday: Yup.string().required("ESTE CAMPO ES OBLIGATORIO"),
})

export const vote = Yup.object().shape({
  userName: Yup.string().required("ESTE CAMPO ES OBLIGATORIO"),
  email: Yup.string()
    .email("Email inválido")
    .required("ESTE CAMPO ES OBLIGATORIO"),
  province: Yup.string().required("ESTE CAMPO ES OBLIGATORIO"),
  // location: Yup.string()
  // 	.required('ESTE CAMPO ES OBLIGATORIO'),
  instagram: Yup.string().required("ESTE CAMPO ES OBLIGATORIO"),
  question: Yup.string().required("Ingrese un mensaje"),
  birthday: Yup.string().required("ESTE CAMPO ES OBLIGATORIO"),
})
